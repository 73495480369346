/* src/SearchPage.css */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
.search-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height */
    flex-direction: column;
  }
  
  .search-form {
    
    padding: 40px;
    
    
    text-align: center;
    max-width: 80%;
    width: 100%;
  }
  
  .search-form h1 {
    margin-bottom: 30px;
    font-size: 3rem;
    color: #333;
    font-family: 'Montserrat','Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .search-input {
    width: 100%;
    padding: 15px;
    font-size: 5rem;
    border: none;
    border-bottom: 2px solid black;
    font-family: 'Montserrat','Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    transition: border-color 0.3s;
    outline: none;
    text-align: center;
  }
  
  .search-input:focus {
    border-color: #007bff;
  }
  
  .search-button {
    width: 40%;
    padding: 15px;
    margin-top: 20px;
    font-size: 18px;
    font-family: 'Montserrat','Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .search-button:hover {
    background-color: #0056b3;
  }

  .main-logo{
    height: 15rem;
    width: auto;
  }
  
  /* Responsive Design */
  @media (max-width: 500px) {
    .search-form {
      padding: 20px;
    }
  
    .search-form h1 {
      font-size: 20px;
      margin-bottom: 20px;
    }
  
    .search-input,
    .search-button {
      font-size: 16px;
      padding: 12px;
    }

    .main-logo{
        height: 10rem;
        width: auto;
      }
  }
  