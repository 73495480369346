/* src/CertificatePage.css */

.certificate-container {
  text-align: center;
  margin: 50px auto;
  padding: 20px;
  background-color: #fff;
  max-width: 90%;
  font-family: 'Montserrat','Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
 
}

.certificate-container h1 {
  color: #333;
  margin-bottom: 30px;
}

.student-info {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  flex-wrap: wrap; /* For responsiveness */
}

.certificate-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
  border: 2px solid #09397b;
}

.student-details {
  text-align: left;
  
}

.student-details p {
  font-size: 18px;
  margin: 5px 0;
}

.certificate-image-container {
  margin-top: 20px;
}

.certificate-pdf-image {
  width: 80%;
 
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.download-button {
  margin-top: 20px;
  display: inline-block;
  padding: 15px 30px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  font-size: 18px;
  transition: background-color 0.3s, transform 0.2s;
}

.download-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.download-button:active {
  transform: scale(0.95);
}

/* Responsive Design */
@media (max-width: 600px) {
  .certificate-container {
    padding: 15px;
  }

  .student-info {
    flex-direction: column;
    align-items: center;
  }

  .certificate-image {
    margin-right: 0;
    margin-bottom: 15px;
  }

  .student-details {
    text-align: center;
    max-width: 100%;
  }

  .certificate-pdf-image {
    width: 100%;
  }

  .download-button {
    padding: 12px 25px;
    font-size: 16px;
  }
}
